import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'
import { Button, Header, Icon, Segment } from 'semantic-ui-react'


const OkContatoPage = () => (
  <Layout>
    <SEO title="Contato Recebido" />
    <Segment raised placeholder>
      <Header icon>
        <Icon name='envelope' color='blue'/>
        Recebemos sua mensagem, Entraremos em contato em breve !
      </Header>
      <Link to='/'>
        <Button color='yellow'>Voltar para página inicial</Button>
      </Link>
    </Segment>
  </Layout>
)

export default OkContatoPage
